import { useEffect, useState } from 'react';
import { WhatsAppOutlined } from '@ant-design/icons';
import { Card, Input, Button, Form, message, Row, Divider, Space, Modal, Progress, notification, Spin } from 'antd';
import Text from 'antd/es/typography/Text';
import { httpRequest } from '../../../helpers/api';
import { getErrorMessage, getStandardError } from '@qlibs/react-components';
import Title from 'antd/es/typography/Title';
import DebounceSelect, { DebounceSelectValue } from './DebounceSelect';
import { UserProperties } from '../../../services/openapi';
import { useDebounce } from 'use-debounce';

type Props = {
  onOTPSent: (detail: any) => void;
  onRefresh: () => void;
}

export default function FormRequestVerification(props: Props) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState<'search' | 'manual'>('search');
  const [formData, setFormData] = useState<{name: string | undefined; phone: string | undefined}>({name: undefined, phone: undefined});
  const [selectedUser, setSelectedUser] = useState<DebounceSelectValue>();
  const [step, setStep] = useState<
    'check_phone' | 
    'create_user' | 
    'send_otp' | undefined>();

  const [isLoadingCheckPhone, setIsLoadingCheckPhone] = useState(false);
  const [phoneValue] = useDebounce(formData.phone, 500);

  useEffect(() => {
    if (phoneValue) {
      checkPhone(phoneValue);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneValue]);

  const checkPhone = async (phoneNumber: string) => {
    setIsLoadingCheckPhone(true);
    console.info('checkPhone', phoneNumber);
    try {
      const res = await httpRequest.post('/app-otp/check-user', {
        phone: phoneNumber,
        name: formData.name,
        autoCreateUserIfNotExist: false,
      });

      console.info('res checkPhone', res);
      if (res.data.payload) {
        message.warning(`Phone number ${res.data.payload.phone} already exist`);
        setFormData((old) => ({ ...old, name: res.data.payload.name }));
        form.setFieldValue('name', res.data.payload.name);
      } else {
        // not found, do nothing
      }
      setIsLoadingCheckPhone(false);
    } catch (err) {
      getStandardError(err, {
        formRef: form,
        showToast: true,
        triggerFormValidation: true,
      });
      setIsLoadingCheckPhone(false);
      return;
    }
  }

  const sendOTP = async (values: any, via: 'SMS' | 'WA') => {
    try {
      setIsLoading(true);
      const res = await httpRequest.post(`/app-otp/otp`, {
        to: values.phone,
        name: values.name,
        via,
      });

      const otpData = res.data.payload;

      setStep(undefined);

      props.onOTPSent(otpData);
      message.success('OTP has been sent');
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      message.error(getErrorMessage(error));
    } finally {
      props.onRefresh();
    }
  };

  const handleSubmit = (values: any) => {
    const { via, ...rest } = values;
    console.info('via', via);
    console.info('rest', rest);
    if (mode === 'manual') {
      handleManual(via);
    } else {
      if (!selectedUser) {
        message.error('Please type to search and select a customer');
        return;
      }
      sendOTP({name: selectedUser.title, phone: selectedUser.value}, via);
    }
  }

  const handleSubmitButton = (e: any, via: 'WA' | 'SMS') => {
    e.preventDefault();
    form.setFieldsValue({ via: via });
    form.submit();
  };

  const handleManual = async (via: 'WA' | 'SMS') => {
    // check phone
    setStep('check_phone');

    try {
      await httpRequest.post('/app-otp/check-user', {
        ...formData,
        autoCreateUserIfNotExist: true,
      });
    } catch(err) {
      getStandardError(err, {
        formRef: form,
        showToast: true,
        triggerFormValidation: true,
      });
      return;
    }
    
    setStep('send_otp');
    sendOTP(formData, via);
  }

  return (
    <Card bordered={false}>
      <Row style={{ marginBottom: 10 }}>
        <Title level={4}>Request Verification</Title>
        <Divider type="vertical" style={{ marginTop: 10 }} />
        <Text
          style={{
            fontSize: 12,
            marginLeft: 3,
            marginTop: 7,
            color: 'darkgrey',
          }}
        >
          OTP will be sent to this phone number
        </Text>
      </Row>

      <Form
        form={form}
        name="formRequest"
        layout="vertical"
        autoComplete="off"
        onFinish={handleSubmit}
      >
        {mode === 'search' && (
          <DebounceSelect
            value={selectedUser}
            placeholder="Search by name or phone"
            fetchOptions={searchUser}
            onClear={() => {
              setSelectedUser(undefined);
            }}
            onChange={(
              newValue: DebounceSelectValue | DebounceSelectValue[]
            ) => {
              console.info('onChange', newValue);
              if (Array.isArray(newValue)) {
                setSelectedUser(newValue[0]);
              } else {
                setSelectedUser(newValue);
              }
            }}
            style={{ width: '100%' }}
            optionRender={(option) => {
              return (
                <Space>
                  <span>{option.data.name}</span>
                  <span style={{ color: 'darkgrey' }}>{option.data.phone}</span>
                </Space>
              );
            }}
            onInputManual={() => setMode('manual')}
          />
        )}

        {mode === 'manual' && (
          <Form.Item
            label="Customer Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Customer name is required',
              },
              {
                max: 50,
                message: 'Max 50 characters',
              },
            ]}
          >
            <Input
              name="name"
              placeholder="Type customer name"
              value={formData.name}
              autoComplete="off"
              autoFocus={true}
              onChange={(e) =>
                setFormData((old) => ({ ...old, name: e.target.value }))
              }
            />
          </Form.Item>
        )}
        {mode === 'manual' && (
          <Form.Item
            label="Phone Number"
            name="phone"
            rules={[
              {
                required: true,
                message: 'Phone number is required',
              },
              {
                pattern: /^\d{9,15}$/,
                // pattern: /^[0-9+\s-]{15}$/,
                message:
                  'The phone number must be between 9 and 15 characters and can only include numbers',
              },
            ]}
          >
            <Input
              name="phone"
              placeholder="Example: 08XXXXXXXXXX"
              value={formData.phone}
              autoComplete="off"
              onChange={(e) =>
                setFormData((old) => ({ ...old, phone: e.target.value }))
              }
              suffix={isLoadingCheckPhone && <Spin />}
            />
          </Form.Item>
        )}

        <Form.Item name='via' style={{display: 'none'}}>
          <></>
        </Form.Item>
        
        <Button
          type="primary"
          onClick={(e) => handleSubmitButton(e, 'WA')}
          // onClick={() => form.submit()}
          style={{ marginTop: mode === 'search' ? 20 : 0, marginRight: 10 }}
          loading={isLoading}
        >
          <WhatsAppOutlined />
          Send via Whatsapp
        </Button>

        {/* <Button
          name="send_code_via_sms"
          type="primary"
          onClick={(e) => sendOTP(e, 'SMS')}
        >
          <MailOutlined />
          Send Code via SMS
        </Button> */}
      </Form>

      <Modal open={!!step} footer={false} width={600} closable={false}>
        <Progress
          percent={step === 'check_phone' ? 50 : step === 'send_otp' ? 100 : 0}
          strokeColor={{
            '0%': '#108ee9',
            '100%': '#87d068',
          }}
        />
        <span>{step === 'check_phone' ? 'Checking phone number...' : step === 'send_otp' ? 'Sending otp...' : 'Please wait...'}</span>
      </Modal>
    </Card>
  );
}

async function searchUser(search: string): Promise<DebounceSelectValue[]> {
  if (!search) {
    return [];
  }
  console.log('fetching user', search);

  return httpRequest.get('/users?limit=50&roles=all&search=' + search).then((res) =>
    res.data.payload.results
      .filter((user: UserProperties) => user.phone)
      .map((user: UserProperties) => ({
        title: user.name,
        label: `${user.name} ${user.phone ? '(' + user.phone + ')' : ''}`,
        value: user.phone,
        ...user,
      }))
  );
}
