import { useState } from "react";
import { httpRequest } from "../helpers/api";
import { getErrorMessage } from "@qlibs/react-components";
import { message } from "antd";

export const useExportExcel = () => {
  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);

  const exportExcel = async (api: string, fileName: string, query?: any) => {
    try {
      setIsLoadingExport(true);

      const response = await httpRequest.get(api, {
        params: {
          ...query,
          limit: 999999,
        },
        responseType: "blob",
      });

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", fileName);

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);

      setIsLoadingExport(false);

      message.success("Success export excel");
    } catch (error) {
      setIsLoadingExport(false);
      console.error("Error exporting Excel:", error);
      message.error(getErrorMessage(error));
    }
  };

  return {
    isLoadingExport,
    exportExcel,
  };
};
