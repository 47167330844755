import { getErrorMessage, HeaderSection } from '@qlibs/react-components'
import { message, Spin } from 'antd'
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { httpRequest } from '../../helpers/api';
import { sleep } from '../../helpers/sleep';
import RowForm from './components/RowForm';
import VerificationDetailHistory from './components/VerificationDetailHistory';
import { VerificationProperties } from './verification.type';

const VerificationDetail = () => {
  const navigate = useNavigate();
  const {otpId} = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [detail, setDetail] = useState<VerificationProperties | undefined>(undefined);

  useEffect(() => {
    fetchDetail();
  }, [otpId]);

  const fetchDetail = async () => {
    if (!otpId) {
      return;
    }
    
    try {
      setIsLoading(true);
      const res = await httpRequest.get('app-otp/otp/' + otpId);

      if (res && res.data && res.data.payload) {
        setDetail(res.data.payload);
      }
    } catch (err) {
      message.error(getErrorMessage(err));
    } finally {
      await sleep(1000);
      setIsLoading(false);
    }
  };

  return (
    <>
      <HeaderSection
        title={'Verification'}
        subtitle={'Verify Person using OTP'}
      />
      <Spin spinning={isLoading}>
        <RowForm
          detail={detail}
          setDetail={setDetail}
          onRefresh={() => {
            navigate(0);
          }}
        />
      </Spin>

      {otpId ? <VerificationDetailHistory otpId={otpId} /> : false}
    </>
  );
}

export default VerificationDetail