import { DetailItem, getErrorMessage } from '@qlibs/react-components';
import { Card, Row, Col, Alert, Input, Button, Form, message, Modal, Divider } from 'antd';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import { useState } from 'react';
import { httpRequest } from '../../../helpers/api';
import { VerificationProperties } from '../verification.type';

type Props = {
  detail?: VerificationProperties;
  onRefresh: () => void;
}

export default function FormInputOTP({detail, onRefresh}: Props) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const {id: otpId, status, name, to: phoneNumber} = detail || {};

  const handleSubmit = async (values: { otp: string }) => {
    try {
      setIsLoading(true);
      await httpRequest.post(`/app-otp/otp/${otpId}/verify`, {
        otp: values.otp,
      });

      // message.success('OTP has been verified');
      Modal.success({
        title: 'OTP Verification Confirmed',
        content: 'Phone Number and OTP are match.',
        onOk: () => {
          onRefresh();
        },
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      // message.error(getErrorMessage(error));

      Modal.error({
        title: 'OTP Verification Failed',
        content:
          getErrorMessage(error) ||
          'The OTP code you entered is incorrect. Please try again.',
          onOk: () => {
            onRefresh();
          }
      });
      setIsLoading(false);
    }
  };

  return (
    <Card bordered={false} loading={isLoading}>
      <Row style={{ marginBottom: 10 }}>
        <Title level={4}>Input OTP</Title>
        <Divider type="vertical" style={{ marginTop: 10 }} />
        <Text
          style={{
            fontSize: 12,
            marginLeft: 3,
            marginTop: 7,
            color: 'darkgrey',
          }}
        >
          Input OTP to verify the user
        </Text>
      </Row>

      {status === 'VERIFICATION_VALID' ? (
        <Row style={{ marginBottom: 0 }}>
          <Col span={24}>
            <Alert
              message="Verified"
              description="The OTP has been successfully verified."
              type="success"
              showIcon
            />
          </Col>
          <Col span={12}>
            <DetailItem label="Phone Number" value={phoneNumber} />
          </Col>
          <Col span={12}>
            <DetailItem label="Customer Name" value={name} />
          </Col>
        </Row>
      ) : status === 'OTP_SENT' ? (
        <Row style={{ marginBottom: 0 }}>
          <Col span={24}>
            <Alert
              // message={`Status: OTP Sent`}
              message="OTP has been sent. Please ask OTP from your customer."
              type="warning"
              showIcon
            />
          </Col>
          <Col span={12}>
            <DetailItem label="Phone Number" value={phoneNumber} />
          </Col>
          <Col span={12}>
            <DetailItem label="Customer Name" value={name} />
          </Col>
        </Row>
      ) : status === 'VERIFICATION_INVALID' ? (
        <Row style={{ marginBottom: 0 }}>
          <Col span={24}>
            <Alert
              // message={`Status: Invalid`}
              message="Previous OTP is invalid. Please ask OTP from your customer."
              type="error"
              showIcon
            />
          </Col>
          <Col span={12}>
            <DetailItem label="Phone Number" value={phoneNumber} />
          </Col>
          <Col span={12}>
            <DetailItem label="Customer Name" value={name} />
          </Col>
        </Row>
      ) : (
        false
      )}

      {status !== 'VERIFICATION_VALID' && (
        <Form
          form={form}
          name="otpForm"
          layout="vertical"
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <Form.Item
            name="otp"
            label="OTP"
            rules={[
              {
                required: true,
                message: 'wajib diisi, data tidak boleh kosong',
              },
            ]}
          >
            <Input
              name="otp"
              placeholder="XXXXXX"
              autoComplete="off"
              autoFocus={otpId ? true : false}
            />
          </Form.Item>

          {/* {otpId && detail ? <div>OTP: {detail?.otp}</div> : false} */}

          <Button type="primary" htmlType="submit">
            Verify OTP
          </Button>
        </Form>
      )}
    </Card>
  );
}