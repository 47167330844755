import {
  HomeOutlined,
  FileTextOutlined,
  TableOutlined,
  TagOutlined,
  PictureOutlined,
  InfoCircleOutlined,
  EnvironmentOutlined,
  BellOutlined,
  CalendarOutlined,
  UsergroupDeleteOutlined,
  UserOutlined,
  PlayCircleOutlined,
  MonitorOutlined,
  FireOutlined,
  SafetyCertificateOutlined,
  ZhihuOutlined,
  WhatsAppOutlined,
  SelectOutlined,
} from "@ant-design/icons";

type IMenu = {
  type?: "group" | "submenu";
  key?: string;
  label: string;
  icon?: any;
  permission?: boolean | string[];
  description?: string;

  children?: IMenu[];
};
export const MENUS: IMenu[] = [
  // {
  //   key: '/dashboard',
  //   label: 'Dashboard',
  //   icon: <HomeOutlined />,
  //   permission: true,
  //   description: 'See insight here',
  // },
  {
    permission: ['USER.LIST'],
    key: '/verification',
    label: 'Verification',
    icon: <SelectOutlined />,
  },
  // {
  //   type: 'group',
  //   label: 'MASTER DATA',
  //   children: [
  //     {
  //       permission: ['STORE.LIST', 'PRODUCT_STORE.LIST'],
  //       key: '/stores',
  //       label: 'Merchant / Store',
  //       icon: <FileTextOutlined />,
  //       description: 'Manage your stores here',
  //     },
  //     {
  //       key: '/products',
  //       permission: ['PRODUCT.LIST'],
  //       type: 'submenu',
  //       label: 'Product',
  //       icon: <TableOutlined />,
  //       description: 'Manage your products here',
  //       children: [
  //         {
  //           permission: ['CATEGORY.LIST', 'PRODUCT_CATEGORY.LIST'],
  //           key: '/categories',
  //           label: 'Category',
  //           icon: <TagOutlined />,
  //         },
  //         {
  //           permission: ['PRODUCT.LIST'],
  //           key: '/products',
  //           label: 'Product',
  //           icon: <TableOutlined />,
  //         },
  //       ],
  //     },
  //     {
  //       key: '/promo',
  //       label: 'Promo',
  //       icon: <PictureOutlined />,
  //       permission: ['PROMO.LIST'],
  //       description: 'Manage your promo here',
  //     },
  //     {
  //       key: '/article',
  //       permission: ['ARTICLE.LIST'],
  //       type: 'submenu',
  //       label: 'News & Article',
  //       icon: <InfoCircleOutlined />,
  //       children: [
  //         {
  //           key: '/article',
  //           label: 'View All',
  //           icon: <FileTextOutlined />,
  //           permission: ['ARTICLE.LIST'],
  //         },
  //         {
  //           key: '/article/categories',
  //           label: 'Categories',
  //           icon: <FileTextOutlined />,
  //           permission: ['ARTICLE.LIST'],
  //         },
  //         {
  //           key: '/article/tags',
  //           label: 'Tags',
  //           icon: <FileTextOutlined />,
  //           permission: ['ARTICLE.LIST'],
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   type: 'group',
  //   label: 'CONFIGURATION',
  //   children: [
  //     {
  //       permission: ['SPONSOR.LIST'],
  //       key: '/sponsors',
  //       label: 'Sponsor',
  //       icon: <InfoCircleOutlined />,
  //     },
  //     {
  //       permission: ['BANNER.LIST'],
  //       key: '/banner',
  //       label: 'Banner',
  //       icon: <PictureOutlined />,
  //     },
  //     {
  //       permission: ['APP_CONFIG.LIST'],
  //       key: '/preference',
  //       label: 'Preference',
  //     },
  //     {
  //       permission: true,
  //       key: '/tnc-and-privacy-policy',
  //       label: 'TNC and Privacy',
  //       icon: <InfoCircleOutlined />,
  //     },
  //     // {
  //     //   permission: ['FAQ.LIST'],
  //     //   key: '/faq-group',
  //     //   label: 'FAQ Group',
  //     //   icon: <InfoCircleOutlined />,
  //     // },
  //     {
  //       permission: ['FAQ.LIST'],
  //       key: '/faqs',
  //       label: 'FAQ',
  //       icon: <InfoCircleOutlined />,
  //     },
  //     {
  //       permission: ['REGION.LIST'],
  //       key: '/regions',
  //       label: 'Region',
  //       icon: <EnvironmentOutlined />,
  //     },
  //     // {
  //     //   key: '/basic-configurations',
  //     //   label: 'Basic Configuration',
  //     //   icon: <LaptopOutlined />,
  //     // },
  //   ],
  // },
  // {
  //   type: 'group',
  //   label: 'TRANSACTION',
  //   children: [
  //     {
  //       permission: ['NEWSLETTER.LIST'],
  //       key: '/email-subscriptions',
  //       label: 'Email Subscription',
  //       icon: <BellOutlined />,
  //     },
  //     {
  //       permission: ['NOTIFICATION_SCHEDULE.LIST'],
  //       key: '/notification-schedules',
  //       label: 'Notification Schedule',
  //       icon: <CalendarOutlined />,
  //     },
  //   ],
  // },
  // {
  //   type: "group",
  //   label: "MULTI LANGUAGES",
  //   children: [
  //     {
  //       permission: true,
  //       key: "/multi-languages",
  //       label: "Languages",
  //       icon: <ZhihuOutlined />,
  //     },
  //   ],
  // },
  {
    permission: ['USER.LIST'],
    key: '/customer',
    label: 'Customer',
    icon: <UsergroupDeleteOutlined />,
  },
  {
    permission: ['USER.LIST'],
    key: '/admin',
    label: 'Manage Admin',
    icon: <UsergroupDeleteOutlined />,
  },
  // {
  //   type: 'group',
  //   label: 'USERS',
  //   children: [
  //     {
  //       permission: ['USER.LIST'],
  //       key: '/admin',
  //       label: 'Admin',
  //       icon: <UsergroupDeleteOutlined />,
  //     },
  //     // {
  //     //   permission: ['USER.LIST'],
  //     //   key: '/customer',
  //     //   label: 'Customer',
  //     //   icon: <UsergroupDeleteOutlined />,
  //     // },
  //     // {
  //     //   permission: ['ROLE.LIST'],
  //     //   key: '/role',
  //     //   label: 'Role & Permission',
  //     //   icon: <SafetyCertificateOutlined />,
  //     // },
  //   ],
  // },
  // {
  //   type: 'group',
  //   label: 'AUDIO',
  //   children: [
  //     {
  //       permission: true,
  //       key: '/audio',
  //       label: 'Audio',
  //       icon: <PlayCircleOutlined />,
  //     },
  //   ],
  // },
  // {
  //   type: 'group',
  //   label: 'INTEGRATION',
  //   children: [
  //     // {
  //     //   permission: ['USER.LIST'],
  //     //   key: '/wabot',
  //     //   label: 'WA BOT',
  //     //   icon: <WhatsAppOutlined />,
  //     // },
  //     {
  //       permission: ['USER.LIST'],
  //       key: '/bc-integration-logs',
  //       label: 'BC Integration Logs',
  //       icon: <InfoCircleOutlined />,
  //     },
  //     {
  //       permission: ['MONITOR_API.LIST'],
  //       key: '/monitor-api',
  //       label: 'Monitor API',
  //       icon: <MonitorOutlined />,
  //     },
  //     {
  //       permission: ['USER.LIST'],
  //       key: '/trackme',
  //       label: 'Track Me',
  //       icon: <FireOutlined />,
  //     },
  //   ],
  // },
];

export const getFlatMenus = (menus: IMenu[]) => {
  return menus
    .reduce((acc, curr) => {
      if (curr.children) {
        curr.children.forEach((child: IMenu) => {
          acc.push(child);

          if (child.children) {
            child.children.forEach((child2: IMenu) => {
              acc.push(child2);
            });
          }
        });
      }
      acc.push(curr);
      return acc;
    }, [] as any[])
    .filter((item: any) => item.key)
    .filter((item: any) => item.type !== "group");
};

export const MENUS_FLAT = getFlatMenus(MENUS);
console.info("menus_flat", MENUS_FLAT);

export const getMenu = () => {
  return MENUS_FLAT.find(
    (item: any) => item.key === window?.location?.pathname
  );
};
