import {
    Form,
    Input,
    message,
    Radio,
    Select,
    Space,
    Button,
    Card,
    Spin,
  } from 'antd';
  import React, { useEffect } from 'react';
  import { useNavigate } from 'react-router-dom';
  import { generateFullname, getStandardError, HeaderSection, useFetchList } from '@qlibs/react-components';
  import { httpRequest } from '../../helpers/api';
  import { generateFormRules } from '../../helpers/formRules';
  import styled from 'styled-components';
  import { ICreateUser, initialUser } from './types/user.type';
  import CONFIG from '../../const/config';
  import SectionContent from '../../components/SectionContent';
  import { getErrorMessage } from '@qlibs/react-components';
  import { RoleProperties } from '../../services/openapi';
  import { CustomFormItemPer1, CustomFormItemPer2, CustomFormItemPer3 } from './components/CustomFormItem';
  import config from '../../const/config';
  import { generate } from 'generate-password-browser'
  import { Checkbox } from 'antd/lib';
  
  type Props = {
    userType?: 'admin' | 'customer';
  };
  
  const { Option } = Select;
  
  const CreateCustomer = (props: Props) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
  
    const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
    const [customer, setCustomer] = React.useState<ICreateUser>(initialUser);
  
    const [password, setPassword] = React.useState<string>()
    const [isCheckedSendAccountInformation, setIsCheckedSendAccountInformation] = React.useState<boolean>(false)
  
    const { data: roles, isLoading: isLoadingRole } = useFetchList<RoleProperties>({
      httpRequest: httpRequest as any,
      endpoint: 'roles',
      limit: 1000,
    })
    const listRole = roles.filter((role) =>
      props.userType === 'admin'
        ? role.roleName.toLowerCase().includes('admin')
        : !role.roleName.toLowerCase().includes('admin')
    );
  
    useEffect(() => {
      const roleId = roles.find((role) =>
        role.roleName.toLowerCase().includes(props.userType || '')
      )?.roleId || ''
      if (roleId) {
        form.setFieldValue('roleId', roleId);
      }
    }, [roles])
  
    const createCustomer = async (value: ICreateUser) => {
      try {
        setIsLoadingAction(true);
  
        const fullName = generateFullname({
          firstName: value.firstName,
          middleName: value.middleName,
          lastName: value.lastName,
        });
        const name = value.name || fullName;
  
        const formData = {
          name,
          firstName: value.firstName || name,
          middleName: value.middleName,
          lastName: value.lastName,
        //   email: value.email,
        //   password: value.password,
          phone: value.phone || null,
        //   roleId: value.roleId ? value.roleId : listRole[0]?.roleId,
        //   sendAccountInformation: isCheckedSendAccountInformation,
          // userType: props.userType === 'customer' ? 'customer' : value.userType,
        //   status: value.status,
        };
  
        await httpRequest.post('/users/add-customer', formData);
  
        message.success(
          `Success add ${name}`
        );
        navigate('/' + props.userType);
      } catch (error) {
        getStandardError(error, { triggerFormValidation: true, formRef: form, showToast: true });
      } finally {
        setIsLoadingAction(false);
      }
    };
  
    const handleSubmit = async (values: ICreateUser) => {
      console.log(values);
  
      createCustomer(values);
    };
  
    const generatePassword = () => {
      var password = generate({
        length: Math.floor(Math.random() * (16 - 14 + 1) + 14),
        numbers: true
      })
      // setPassword(password)
  
      form.setFieldValue('password', password)
  
      console.log(password);
  
    }
  
    return (
      <React.Fragment>
        <HeaderSection
          icon="back"
          title={'Add ' + (props.userType === 'admin' ? 'Admin' : 'Customer')}
          subtitle={'Create new user'}
          showCancelButton
          showSaveButton
          onSave={() => form.submit()}
        />
  
        <Card>
          <SectionContent
            groupTitle={`User Information`}
            subTitle="These are data user information, you can change anything"
          >
            <Form
              form={form}
              name="profileForm"
              layout="vertical"
              onFinish={handleSubmit}
              autoComplete="off"
              style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
            >
              {CONFIG.user.modeEditName === 'fullname' ? (
                <CustomFormItemPer1
                  label="Name"
                  name="name"
                  rules={generateFormRules('Name', [
                    'required',
                    'letter-and-space',
                    'max-text-length',
                  ])}
                >
                  <Input placeholder="Input name" />
                </CustomFormItemPer1>
              ) : (
                <React.Fragment>
                  <CustomFormItemPer3
                    label="First Name"
                    name="firstName"
                    rules={generateFormRules('First Name', ['required'])}
                  >
                    <Input placeholder="Input first name" />
                  </CustomFormItemPer3>
                  <CustomFormItemPer3 label="Middle Name" name="middleName">
                    <Input placeholder="Input middle name" />
                  </CustomFormItemPer3>
                  <CustomFormItemPer3 label="Last Name" name="lastName">
                    <Input placeholder="Input last name" />
                  </CustomFormItemPer3>
                </React.Fragment>
              )}
  
              {/* <CustomFormItemPer3
                label="Email"
                name="email"
                rules={generateFormRules('Email', ['required', 'email'])}
              >
                <Input placeholder="Input email address" />
              </CustomFormItemPer3>
  
              <CustomFormItemPer3
                label="Password"
                name="password"
                rules={generateFormRules('Password', ['required', 'password'])}
              >
                <Input.Password
                  // onChange={(e) => setPassword(e.target.value)}
                  // value={password}
                  placeholder="Input password" />
              </CustomFormItemPer3>
  
              <CustomFormItemPer3>
                <Button
                  style={{
                    backgroundColor: '#DDDDDD',
                    // width: '30%',
                    marginLeft: '10px',
                    marginTop: '30px',
                  }}
                  onClick={generatePassword}>
                  Generate Password
                </Button>
              </CustomFormItemPer3> */}
              
              <CustomFormItemPer1
                label="Phone Number"
                name="phone"
                rules={generateFormRules(
                  'Phone number',
                  config.user.phoneRequired
                    ? ['required', 'phoneNumber']
                    : ['phoneNumber']
                )}
              >
                <Input
                  onChange={(event) =>
                    setCustomer({ ...customer, phone: event.target.value })
                  }
                  placeholder="Input phone number"
                />
              </CustomFormItemPer1>
  
              {/* {props.userType === "admin" ? (
            <CustomFormItemPer1
              label="Email"
              name="email"
              rules={generateFormRules("Email", ["required", "email"])}
            >
              <Input
                onChange={(event) =>
                  setCustomer({ ...customer, email: event.target.value })
                }
                placeholder="Input email address"
              />
            </CustomFormItemPer1>
          ) : (
            <CustomFormItemPer1
              label="Phone Number"
              name="phone"
              rules={generateFormRules("Phone number", [
                "required",
                "phoneNumber",
              ])}
            >
              <Input
                onChange={(event) =>
                  setCustomer({ ...customer, phone: event.target.value })
                }
                placeholder="Input number"
              />
            </CustomFormItemPer1>
          )} */}
  
              {/* {props.userType === 'admin' && listRole.length > 1 ? (
                <CustomFormItemPer1
                  label="Role"
                  name="roleId"
                  rules={[
                    {
                      message: 'The Role is required.',
                      required: true,
                    },
                  ]}
                >
                  <Select placeholder="Select role" style={{ width: '100%' }}>
                    {listRole.map((role, key) => (
                      <Option key={key} value={role.roleId}>
                        {role.roleName}
                      </Option>
                    ))}
                  </Select>
                </CustomFormItemPer1>
              ) : (
                <React.Fragment></React.Fragment>
              )}
  
              <CustomFormItemPer1
                label="Status"
                name="status"
                rules={[
                  {
                    message: 'The Status is required.',
                  },
                ]}
              >
                <Radio.Group value={customer.status} defaultValue="active">
                  <CustomRadio value="active">Active</CustomRadio>
                  <CustomRadio value="inactive">Inactive</CustomRadio>
                </Radio.Group>
              </CustomFormItemPer1>
              <Checkbox onChange={(e) => setIsCheckedSendAccountInformation(e.target.checked)}>Send account information & password via email</Checkbox> */}
            </Form>
          </SectionContent>
        </Card>
      </React.Fragment>
    );
  };
  
  export default CreateCustomer;
  
  const CustomRadio = styled(Radio)`
    margin-right: 5rem;
    .ant-radio-checked .ant-radio-inner {
      border-color: #1e1e1e;
      border-width: 2px;
      box-shadow: none;
    }
    .ant-radio:hover .ant-radio-inner {
      background-color: white;
    }
    .ant-radio-checked .ant-radio-inner:after {
      background-color: #1e1e1e;
    }
  `;
  