import React, { useMemo, useRef, useState } from 'react';
import { Button, Empty, Select, Spin } from 'antd';
import type { SelectProps } from 'antd';
import debounce from 'lodash/debounce';
import { EditOutlined } from '@ant-design/icons';

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
  onInputManual: () => void;
}

export default function DebounceSelect<
  ValueType extends {
    key?: string;
    label: React.ReactNode;
    value: string | number;
  } = any
>({
  fetchOptions,
  debounceTimeout = 800,
  ...props
}: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ValueType[]>([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  console.info('fetchRef.current', fetchRef.current);

  return (
    <Select
      showSearch
      allowClear
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      autoClearSearchValue
      notFoundContent={
        fetching ? (
          <Spin size="small" />
        ) : (
          <Empty
            style={{ color: 'darkgrey' }}
            description={
              // fetchRef.current
              //   ? 'Not found. Try another keyword or input manual'
              'Type to search or input manual'
            }
            imageStyle={{height: 50}}
          >
            <Button type="link" onClick={props.onInputManual} icon={<EditOutlined />}>
              Input Manual
            </Button>
          </Empty>
        )
      }
      loading={fetching}
      {...props}
      onClear={() => {
        setOptions([]);
        fetchRef.current = 0;
        if (props.onClear) {
          props.onClear();
        }
      }}
      options={options}
    />
  );
}

export type DebounceSelectValue = {
  label: string;
  value: string;
  key: string;
  title: string;
  disabled?: boolean;
};
