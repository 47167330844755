type IConfig = {
  pageTitlePosition: 'top-nav' | 'content';
  /** if top-nav, breadcrumbs will be shown in content. If content, breadcrumbs will be shown in top-nav. */

  defaultPath: string;

  topNav: {
    showWelcomeName: boolean;
  };

  sidebar: {
    logoType: 'image-and-text' | 'text-only';
  };

  user: {
    modeEditName: 'fullname' | 'first-middle-last';

    showEditEmail: boolean;
    changeEmailWithOTP: boolean;

    showEditPhone: boolean;
    changePhoneWithOTP: boolean;

    phoneRequired: boolean;
    emailRequired: boolean;

    hasAddress: boolean;
  };

  myProfile: {
    showPermission: boolean;
  };
};
const CONFIG: IConfig = {
  //config mode bahasa

  pageTitlePosition: 'content',

  defaultPath: '/verification',
  // defaultPath: '/customer',

  topNav: {
    showWelcomeName: false,
  },

  sidebar: {
    logoType: 'image-and-text',
  },

  user: {
    // modeEditName: 'fullname',
    modeEditName: 'first-middle-last',

    showEditEmail: false,
    showEditPhone: true,

    phoneRequired: true,
    changePhoneWithOTP: false,

    emailRequired: false,
    changeEmailWithOTP: true,

    hasAddress: false,
  },

  myProfile: {
    showPermission: false,
  },
};

export const DEFAULT_IMG = '/images/default_image.png';

export const APP_VERSION = process.env.REACT_APP_VERSION_NAME;

export const APP_LOGO_SRC = '/logo.png';
export const APP_LOGO_SRC_COLLAPSED = '/logo.png';

export default CONFIG;
