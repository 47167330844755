import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import {
  AppTable,
  IAppTableColumn,
  PAGE_SIZE_OPTIONS,
  useFetchList,
} from "@qlibs/react-components";
import { Card, Row, Col, Space, Button } from "antd";
import Title from "antd/es/typography/Title";
import { httpRequest } from "../../../helpers/api";
import { ChangeLog } from "../verification.type";
import { useExportExcel } from "../../../hooks/useExportExcell";

type Props = {
  otpId: string;
};
export default function VerificationDetailHistory({ otpId }: Props) {
  const { isLoadingExport, exportExcel } = useExportExcel();

  const { isLoading, data, pagination, changePage, changeLimit } =
    useFetchList<ChangeLog>({
      httpRequest: httpRequest as any,
      limit: +PAGE_SIZE_OPTIONS[0],
      endpoint: "app-otp-change-logs",
      initialQuery: {
        externalId: otpId,
      },
      pageQuery: {
        defaultValue: {
          page: 1,
          search: otpId,
        },
      },
    });

  const columns: IAppTableColumn<ChangeLog>[] = [
    {
      title: "Date & Time",
      dataIndex: "createdAt",
      key: "createdAt",
      type: "datetime",
      width: 250,
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      type: "status",
      width: 350,
      showStatusIcon: true,
      statusReadable: {
        style: "lowercase",
      },
      mappingStatus: {
        CREATE: {
          label: "Initialize",
          tagProps: {
            color: "gray",
          },
          icon: <PlusOutlined />,
        },
        SEND_OTP: {
          label: "Send OTP",
          tagProps: {
            color: "orange",
          },
          icon: <WhatsAppOutlined />,
        },
        VERIFY_SUCCESS: {
          label: "Verify OTP - Success",
          tagProps: {
            color: "#87d068",
          },
          icon: <CheckCircleOutlined />,
        },
        VERIFY_FAILED: {
          label: "Verify OTP - Failed",
          tagProps: {
            color: "#D81F64",
          },
          icon: <CloseCircleOutlined />,
        },
      },
    },
    {
      title: "Action By",
      key: "actionBy",
      render: (text: string, record: any) => record.actionByMeta?.name,
    },
  ];

  return (
    <Card style={{ marginTop: 15 }}>
      <Row style={{ marginBottom: 15 }} className="flex justify-between">
        <Col span={12}>
          <Title level={3}>Logs</Title>
        </Col>

        <Col span={6}>
          <Button
            block
            style={{
              background: "#264284",
              color: "white",
              marginRight: 20,
            }}
            type="primary"
            loading={isLoadingExport}
            onClick={async () => {
              await exportExcel(
                "/app-otp-change-logs/export-excel",
                "Data Verification Request.xlsx"
              );
            }}
          >
            Export Excel
          </Button>
        </Col>

        {/* <Col span={12} style={{ textAlign: 'right' }}>
          <Space>
            <Button type="primary" onClick={fetchList}>
              Refresh
            </Button>
          </Space>
        </Col> */}
      </Row>

      <Row>
        <Col span={24}>
          <AppTable
            keyId="id"
            columns={columns}
            data={data}
            pagination={pagination}
            onChangePage={changePage}
            onChangeLimit={changeLimit}
            isLoading={isLoading}
          />
        </Col>
      </Row>
    </Card>
  );
}
