import React, { useState } from 'react';
import { Button, Divider, Image, message, QRCode } from 'antd';
import { getErrorMessage, HeaderSection } from '@qlibs/react-components';
import { httpRequest } from '../../helpers/api';

export default function WabotScreen() {
  const [isLoadingConnect, setIsLoadingConnect] = useState(false);
  const [step, setStep] = useState<'qr' | 'authenticated'>();
  const [qrValue, setQRValue] = useState<string>();
  const [qrUrl, setQRUrl] = useState<string>();

  const connect = async () => {
    setIsLoadingConnect(true);

    try {
      const res = await httpRequest.get('wabot/init');

      if (res?.data?.payload) {
        setStep(res.data.payload.step);
        if (res.data.payload.step === 'qr') {
          setQRValue(res.data.payload.payload.qrValue);
          setQRUrl(res.data.payload.payload.qrUrl);
        }
      }
      setIsLoadingConnect(false);
    } catch (err) {
      message.error(getErrorMessage(err));
      setIsLoadingConnect(false);
    }
  };

  return (
    <React.Fragment>
      <HeaderSection title="WABOT" />

      <Button loading={isLoadingConnect} onClick={() => connect()}>
        Connect
      </Button>

      <Divider />

      {step === 'qr' && !!qrValue && (
        <div>
          <QRCode value={qrValue} />
        </div>
      )}

      <Divider />

      {step === 'qr' && !!qrUrl && (
        <div>
          <Image src={qrUrl} />
        </div>
      )}
    </React.Fragment>
  );
}
