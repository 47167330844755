import { Form } from 'antd';
import styled from 'styled-components';

export const CustomFormItemPer3 = styled(Form.Item)`
  width: 30%;
  margin-left: 10px;
`;
export const CustomFormItemPer2 = styled(Form.Item)`
  width: 45%;
  margin-left: 10px;
`;
export const CustomFormItemPer1 = styled(Form.Item)`
  width: 92%;
  margin-left: 10px;
`;
