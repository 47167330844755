import { ArrowRightOutlined } from '@ant-design/icons';
import { Row, Col, Card } from 'antd';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { VerificationProperties } from '../verification.type';
import FormInputOTP from './FormInputOTP';
import FormRequestVerification from './FormRequestVerification';
import SummaryByPhone from './SummaryByPhone';
import { isMobile } from 'react-device-detect';

type Props = {
  detail: VerificationProperties | undefined;
  setDetail: (data: VerificationProperties) => void;
  onRefresh: () => void;
}
export default function RowForm({detail, setDetail, onRefresh}: Props) {
  const navigate = useNavigate();
  return (
    <Row gutter={16}>
      {!detail && (
        <Col lg={11} xs={24}>
          <FormRequestVerification
            onOTPSent={(otpData) => {
              setDetail(otpData);
              navigate('/verification/' + otpData.id);
            }}
            onRefresh={onRefresh}
          />
          {!!detail && (
            <Card
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                background: 'darkgrey',
                opacity: 0.8,
                top: 0,
                left: 0,
                padding: 0,
              }}
            />
          )}
        </Col>
      )}
      {!detail && !isMobile && (
        <Col style={{ paddingTop: 80 }}>
          <ArrowRightOutlined style={{ fontSize: 30 }} />
        </Col>
      )}
      {!isMobile && (
        <Col lg={11} xs={24}>
          <FormInputOTP detail={detail} onRefresh={onRefresh} />
          {!detail && (
            <Card
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                background: 'darkgrey',
                opacity: 0.8,
                top: 0,
                left: 0,
                padding: 0,
              }}
            />
          )}
        </Col>
      )}
      {!!detail && (
        <Col lg={13} xs={24}>
          <SummaryByPhone phone={detail.to} />
        </Col>
      )}
    </Row>
  );
}