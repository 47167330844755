import {
    Form,
    Input,
    message,
    Radio,
    Select,
    Space,
    Button,
    Card,
    Row,
    Col,
    Modal,
  } from 'antd';
  import React from 'react';
  import { useNavigate, useParams, useLocation } from 'react-router-dom';
  import { DetailItem, generateFullname, HeaderSection, useFetchList } from '@qlibs/react-components';
  import { httpRequest } from '../../helpers/api';
  import { BaseResponseProps } from '../../types/config.type';
  import { generateFormRules } from '../../helpers/formRules';
  import styled from 'styled-components';
  import { ICreateUser, initialUser } from './types/user.type';
  import CONFIG from '../../const/config';
  import SectionContent from '../../components/SectionContent';
  import { getErrorMessage } from '@qlibs/react-components';
  import { RoleProperties } from '../../services/openapi';
  import { CustomFormItemPer1, CustomFormItemPer3 } from './components/CustomFormItem';
  import config from '../../const/config';
  
  interface ILocation {
    userId: string;
  }
  
  type Props = {
    userType?: 'admin' | 'customer';
  };
  
  interface ResponseProps extends BaseResponseProps<ICreateUser> {}
  
  const { Option } = Select;
  
  const EditCustomer = (props: Props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { userId } = useParams<keyof ILocation>() as ILocation;
    const [form] = Form.useForm();
    const [formAccount] = Form.useForm();
  
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
    const [customer, setCustomer] = React.useState<ICreateUser>(initialUser);
  
    const {data: roles, isLoading: isLoadingRole} = useFetchList<RoleProperties>({
      httpRequest: httpRequest as any,
      endpoint: 'roles',
      limit: 1000,
    })
  
    const listRole = roles.filter((role) =>
      props.userType === 'admin'
        ? role.roleName.toLowerCase().includes('admin')
        : !role.roleName.toLowerCase().includes('admin')
    );
  
    const updateCustomer = async (value: ICreateUser) => {
      try {
        setIsLoadingAction(true);
        
        const fullName = generateFullname({
          firstName: value.firstName,
          middleName: value.middleName,
          lastName: value.lastName,
        });
        const name = value.name || fullName;
  
        const formData = {
          name,
          firstName: value.firstName || name,
          middleName: value.middleName,
          lastName: value.lastName,
          email: value.email,
          phone: value.phone,
          userType: props.userType === 'customer' ? 'customer' : value.userType,
          status: value.status,
        };
  
        await httpRequest.patch('/users/' + userId, formData);
  
        message.success(
          `Success update ${name} data`
        );
        navigate('/' + props.userType);
      } catch (error) {
        message.error(getErrorMessage(error));
      } finally {
        setIsLoadingAction(false);
      }
    };
  
    const handleSubmit = async (values: ICreateUser) => {
      updateCustomer(values);
    };
    
    const handleSubmitAccount = async (values: ICreateUser) => {
      //
    };
    
    React.useEffect(() => {
      if (userId) {
        const fetchCustomer = async () => {
          try {
            setIsLoading(true);
  
            const res = await httpRequest.get<ResponseProps>('/users/' + userId);
            if (res && res.data) {
              setCustomer(res.data.payload);
  
              form.setFieldsValue(res.data.payload);
              formAccount.setFieldsValue({email: res.data.payload.email, phone: res.data.payload.phone});
            } else {
              message.error('Something went wrong');
            }
  
            setIsLoading(false);
          } catch (error) {
            setIsLoading(false);
          }
        };
  
        fetchCustomer();
      }
  
      // eslint-disable-next-line
    }, [userId, location]);
  
    return (
      <React.Fragment>
        <HeaderSection
          icon="back"
          title={
            (userId ? 'Edit' : 'Add') +
            ' ' +
            (props.userType === 'admin' ? 'Admin' : 'Customer')
          }
          subtitle={userId ? 'Manage your user data' : 'Create new user'}
          showCancelButton
          showSaveButton
          onSave={() => form.submit()}
        />
  
        <Card loading={isLoading}>
          <SectionContent
            groupTitle={`Profile Information`}
            subTitle="These are profile information, you can change anything"
          >
            <Form
              form={form}
              name="profileForm"
              layout="vertical"
              onFinish={handleSubmit}
              autoComplete="off"
              style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
            >
              {CONFIG.user.modeEditName === 'fullname' ? (
                <Form.Item
                  label="Name"
                  name="name"
                  rules={generateFormRules('Name', [
                    'required',
                    'letter-and-space',
                    'max-text-length',
                  ])}
                >
                  <Input placeholder="Input name" />
                </Form.Item>
              ) : (
                <React.Fragment>
                  <CustomFormItemPer3
                    label="First Name"
                    name="firstName"
                    rules={generateFormRules('First Name', ['required'])}
                  >
                    <Input />
                  </CustomFormItemPer3>
                  <CustomFormItemPer3 label="Middle Name" name="middleName">
                    <Input />
                  </CustomFormItemPer3>
                  <CustomFormItemPer3 label="Last Name" name="lastName">
                    <Input />
                  </CustomFormItemPer3>
                </React.Fragment>
              )}
  
              <CustomFormItemPer1
                label="Phone Number"
                name="phone"
                rules={generateFormRules(
                  'Phone number',
                  config.user.phoneRequired
                    ? ['required', 'phoneNumber']
                    : ['phoneNumber']
                )}
              >
                <Input
                  onChange={(event) =>
                    setCustomer({ ...customer, phone: event.target.value })
                  }
                  placeholder="Input phone number"
                />
              </CustomFormItemPer1>
            </Form>
          </SectionContent>
        </Card>
      </React.Fragment>
    );
  };
  
  export default EditCustomer;
  
  const CustomRadio = styled(Radio)`
    margin-right: 5rem;
    .ant-radio-checked .ant-radio-inner {
      border-color: #1e1e1e;
      border-width: 2px;
      box-shadow: none;
    }
    .ant-radio:hover .ant-radio-inner {
      background-color: white;
    }
    .ant-radio-checked .ant-radio-inner:after {
      background-color: #1e1e1e;
    }
  `;
  