import { useEffect, useState } from 'react';
import { DetailItem, getErrorMessage } from '@qlibs/react-components';
import { Card, Col, Divider, message, Row, Typography } from 'antd';
import { httpRequest } from '../../../helpers/api';
import { WarningOutlined } from '@ant-design/icons';

const { Text, Title } = Typography;

type Props = {
  phone: string;
};
export default function SummaryByPhone(props: Props) {
  const [summary, setSummary] = useState<{
    totalVerificationRequest: number;
    totalCreated: number;
    totalOtpSent: number;
    totalValid: number;
    totalInvalid: number;
  }>();
  useEffect(() => {
    fetchSummary();
  }, []);
  const fetchSummary = () => {
    httpRequest.get('/app-otp/otp/summary/' + props.phone).then(res => {
      setSummary(res.data.payload);
    }).catch(err => {
      message.error(getErrorMessage(err));
    });
  }
  return (
    <Card>
      <Row style={{ marginBottom: 10 }}>
        <Title level={4}>Insight</Title>
        <Divider type="vertical" style={{ marginTop: 10 }} />
        <Text
          style={{
            fontSize: 12,
            marginLeft: 3,
            marginTop: 7,
            color: 'darkgrey',
          }}
        >
          Summary about this phone number
        </Text>
      </Row>

      <Row>
        <Col span={12}>
          <DetailItem
            label="Total Verification Request"
            value={summary?.totalVerificationRequest || '0'}
          />
        </Col>
      </Row>

      <Row>
        <Col span={7}>
          <DetailItem
            label="Valid Verification"
            value={summary?.totalValid || '0'}
          />
        </Col>
        <Col span={7}>
          {Number(summary?.totalInvalid) > 0 && (
            <span style={{ position: 'absolute', top: 10, left: -25 }}>
              <WarningOutlined style={{ color: 'red', fontSize: 18 }} />
            </span>
          )}
          <DetailItem
            label="Invalid Verification"
            value={summary?.totalInvalid || 0}
          />
        </Col>
        <Col span={10}>
          <DetailItem
            label="OTP Sent - Waiting for Verification"
            value={summary?.totalOtpSent || '0'}
          />
        </Col>
        {/* <Col span={12}>
          <DetailItem label="Created" value={summary?.totalOtpSent || '0'} />
        </Col> */}
      </Row>
    </Card>
  );
}
