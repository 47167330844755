import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import IntegrationBC from "../screens/integrationBC";
import AppLayout from "../screens/layout/AppLayout";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import { Spin } from "antd";
import Logout from "../screens/auth/Logout";
import UserCreate from "../screens/user/Create";
import { useCookies } from "react-cookie";
import { ListLangType, languages } from "../i18n/settings";
import WabotScreen from '../screens/wabot';
import VerificationDetail from '../screens/verification/Detail';
import CreateCustomer from "../screens/user/CreateCustomer";
import EditCustomer from "../screens/user/EditCustomer";

const NotFound = React.lazy(() => import("../NotFound"));
const Preference = React.lazy(() => import("../screens/preference"));

const Login = React.lazy(() => import("../screens/auth/NewLogin"));
const ForgotPassword = React.lazy(
  () => import("../screens/auth/ForgotPassword")
);
const ResetPassword = React.lazy(() => import("../screens/auth/ResetPassword"));

const Dashboard = React.lazy(() => import("../screens/dashboard"));
const Profile = React.lazy(() => import("../screens/profile"));
const ChangeMyPassword = React.lazy(
  () => import("../screens/profile/ChangeMyPassword")
);
const ChangePasswordUser = React.lazy(
  () => import("../screens/user/ChangePassword")
);
const User = React.lazy(() => import("../screens/user"));
const UserEdit = React.lazy(() => import("../screens/user/Edit"));
const UserDetail = React.lazy(() => import("../screens/user/Detail"));

const BasicConfig = React.lazy(() => import("../screens/basicConfig"));

const Role = React.lazy(() => import("../screens/role"));
const RoleEdit = React.lazy(() => import("../screens/role/Edit"));
const RoleDetail = React.lazy(() => import("../screens/role/Detail"));

const UserAddressDetail = React.lazy(
  () => import("../screens/user/userAddress/Detail")
);
const UserAddressEdit = React.lazy(
  () => import("../screens/user/userAddress/Edit")
);
const Verification = React.lazy(
  () => import("../screens/verification")
);

const MultiLanguages = React.lazy(() => import("../screens/multiLanguages"));

const TncPrivacy = React.lazy(() => import("../screens/tncPrivacy"));

const RootNavigator: React.FC = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["i18next"]);
  const [lng, setLng] = useState<ListLangType>("en");

  useEffect(() => {
    const language = cookies.i18next;

    if (language) {
      setLng(language);
    }
  }, [cookies.i18next]);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <PublicRoute>
              <Suspense fallback={<Spin spinning={true} />}>
                <Login lng={lng} />
              </Suspense>
            </PublicRoute>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Suspense fallback={<Spin spinning={true} />}>
                <Login lng={lng} />
              </Suspense>
            </PublicRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <PublicRoute>
              <Suspense fallback={<Spin spinning={true} />}>
                <ForgotPassword />
              </Suspense>
            </PublicRoute>
          }
        />
        <Route
          path="/reset-password/:sessionId"
          element={
            <PublicRoute>
              <Suspense fallback={<Spin spinning={true} />}>
                <ResetPassword />
              </Suspense>
            </PublicRoute>
          }
        />

        <Route path="*" element={<NotFound />} />

        <Route element={<AppLayout lng={lng} />}>
          <Route
            path="/dashboard"
            element={
              <PrivateRoute loginPath="/login">
                <Dashboard lng={lng} />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute loginPath="/login">
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path="/change-password"
            element={
              <PrivateRoute loginPath="/login">
                <ChangeMyPassword />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <PrivateRoute loginPath="/login">
                <User userType="admin" />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/add"
            element={
              <PrivateRoute loginPath="/login">
                <UserCreate userType="admin" />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/:userId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <UserEdit userType="admin" />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/:userId"
            element={
              <PrivateRoute loginPath="/login">
                <UserDetail userType="admin" />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/:userId/change-password"
            element={
              <PrivateRoute loginPath="/login">
                <ChangePasswordUser />
              </PrivateRoute>
            }
          />
          <Route
            path="/customer"
            element={
              <PrivateRoute loginPath="/login">
                <User userType="customer" />
              </PrivateRoute>
            }
          />
          <Route
            path="/customer/add"
            element={
              <PrivateRoute loginPath="/login">
                <CreateCustomer userType="customer" />
              </PrivateRoute>
            }
          />
          <Route
            path="/customer/:userId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <EditCustomer userType="customer" />
              </PrivateRoute>
            }
          />
          <Route
            path="/customer/:userId"
            element={
              <PrivateRoute loginPath="/login">
                <UserDetail userType="customer" />
              </PrivateRoute>
            }
          />
          <Route
            path="/customer/:userId/change-password"
            element={
              <PrivateRoute loginPath="/login">
                <ChangePasswordUser />
              </PrivateRoute>
            }
          />
          <Route
            path="/basic-configurations"
            element={
              <PrivateRoute loginPath="/login">
                <BasicConfig />
              </PrivateRoute>
            }
          />
          <Route
            path="/multi-languages"
            element={
              <PrivateRoute loginPath="/login">
                <MultiLanguages lng={lng} />
              </PrivateRoute>
            }
          />
          <Route
            path="/role"
            element={
              <PrivateRoute loginPath="/login">
                <Role />
              </PrivateRoute>
            }
          />
          <Route
            path="/role/add"
            element={
              <PrivateRoute loginPath="/login">
                <RoleEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/role/:roleId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <RoleEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/role/:roleId"
            element={
              <PrivateRoute loginPath="/login">
                <RoleDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/bc-integration-logs"
            element={
              <PrivateRoute loginPath="/login">
                <IntegrationBC />
              </PrivateRoute>
            }
          />
          <Route
            path="/preference"
            element={
              <PrivateRoute loginPath="/login">
                <Preference />
              </PrivateRoute>
            }
          />
          <Route
            path="/users/:userId/address/:addressId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <UserAddressEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/users/:userId/address/:addressId"
            element={
              <PrivateRoute loginPath="/login">
                <UserAddressDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/wabot"
            element={
              <PrivateRoute loginPath="/login">
                <WabotScreen />
              </PrivateRoute>
            }
          />

          <Route
            path="/tnc-and-privacy-policy"
            element={
              <PrivateRoute loginPath="/login">
                <TncPrivacy />
              </PrivateRoute>
            }
          />

          <Route
            path="/verification"
            element={
              <PrivateRoute loginPath="/login">
                <Verification />
              </PrivateRoute>
            }
          />

          <Route
            path="/verification/:otpId"
            element={
              <PrivateRoute loginPath="/login">
                <VerificationDetail />
              </PrivateRoute>
            }
          />

          <Route
            path="/logout"
            element={
              <PublicRoute>
                <Logout />
              </PublicRoute>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
export default RootNavigator;
